/*
 The main light theme for the application
 */
@use "@angular/material" as mat;

$primary-palette: (
  50: var(--primary-50, map-get(mat.$m2-orange-palette, 50)),
  100: var(--primary-100, map-get(mat.$m2-orange-palette, 100)),
  200: var(--primary-200, map-get(mat.$m2-orange-palette, 200)),
  300: var(--primary-300, map-get(mat.$m2-orange-palette, 300)),
  400: var(--primary-400, map-get(mat.$m2-orange-palette, 400)),
  500: var(--primary-500, map-get(mat.$m2-orange-palette, 500)),
  600: var(--primary-600, map-get(mat.$m2-orange-palette, 600)),
  700: var(--primary-700, map-get(mat.$m2-orange-palette, 700)),
  800: var(--primary-800, map-get(mat.$m2-orange-palette, 800)),
  900: var(--primary-900, map-get(mat.$m2-orange-palette, 900)),
  A100: var(--primary-A100, map-get(mat.$m2-orange-palette, A100)),
  A200: var(--primary-A200, map-get(mat.$m2-orange-palette, A200)),
  A400: var(--primary-A400, map-get(mat.$m2-orange-palette, A400)),
  A700: var(--primary-A700, map-get(mat.$m2-orange-palette, A700)),
  contrast: map-get(mat.$m2-orange-palette, contrast),
);

$secondary-palette: (
  50: var(--secondary-50, map-get(mat.$m2-blue-palette, 50)),
  100: var(--secondary-100, map-get(mat.$m2-blue-palette, 100)),
  200: var(--secondary-200, map-get(mat.$m2-blue-palette, 200)),
  300: var(--secondary-300, map-get(mat.$m2-blue-palette, 300)),
  400: var(--secondary-400, map-get(mat.$m2-blue-palette, 400)),
  500: var(--secondary-500, map-get(mat.$m2-blue-palette, 500)),
  600: var(--secondary-600, map-get(mat.$m2-blue-palette, 600)),
  700: var(--secondary-700, map-get(mat.$m2-blue-palette, 700)),
  800: var(--secondary-800, map-get(mat.$m2-blue-palette, 800)),
  900: var(--secondary-900, map-get(mat.$m2-blue-palette, 900)),
  A100: var(--secondary-A100, map-get(mat.$m2-blue-palette, A100)),
  A200: var(--secondary-A200, map-get(mat.$m2-blue-palette, A200)),
  A400: var(--secondary-A400, map-get(mat.$m2-blue-palette, A400)),
  A700: var(--secondary-A700, map-get(mat.$m2-blue-palette, A700)),
  contrast: map-get(mat.$m2-blue-palette, contrast),
);

$error-palette: (
  50: var(--error-50, map-get(mat.$m2-red-palette, 50)),
  100: var(--error-100, map-get(mat.$m2-red-palette, 100)),
  200: var(--error-200, map-get(mat.$m2-red-palette, 200)),
  300: var(--error-300, map-get(mat.$m2-red-palette, 300)),
  400: var(--error-400, map-get(mat.$m2-red-palette, 400)),
  500: var(--error-500, map-get(mat.$m2-red-palette, 500)),
  600: var(--error-600, map-get(mat.$m2-red-palette, 600)),
  700: var(--error-700, map-get(mat.$m2-red-palette, 700)),
  800: var(--error-800, map-get(mat.$m2-red-palette, 800)),
  900: var(--error-900, map-get(mat.$m2-red-palette, 900)),
  A200: var(--error-A200, map-get(mat.$m2-red-palette, A200)),
  A400: var(--error-A400, map-get(mat.$m2-red-palette, A400)),
  A700: var(--error-A700, map-get(mat.$m2-red-palette, A700)),
  contrast: map-get(mat.$m2-red-palette, contrast),
);

/* Define the applications theme. */
$primary: mat.m2-define-palette($primary-palette);
$accent: mat.m2-define-palette($secondary-palette, A200, A100, A400);
$warn: mat.m2-define-palette($error-palette);

$typography: mat.m2-define-typography-config(
  $font-family: var(--font-family, sans-serif),
);

/* Create the theme object (a Sass map containing all of the palettes). */
$theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    typography: $typography,
  )
);
